import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import {
  ChallengeNameType,
  RespondToAuthChallengeCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import { ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { VALID_PASSWORD_REGEX } from "../../../constants/authentication";
import { useAuthContext } from "../../../context/AuthContext";
import { SessionStorageItem } from "../../../enums/sessionStorage";
import { MfaOption } from "../../../graphql/operations";
import {
  respondWithNewPassword,
  setUserMFAPreference,
  signIn,
} from "../../../services/aws/auth";
import { navigateToRoute } from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";

export const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(
      VALID_PASSWORD_REGEX,
      "Password must contain at least 6 characters, one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)"
    )
    .required("New password is required"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords do not match")
    .required("Confirm new password is required"),
});

const NewUserPassword = () => {
  const { login } = useAuthContext();
  const formTheme = useAuthFormTheme();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitted, isValid },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSubmitDisabled =
    isSubmitting || isEmpty(dirtyFields) || (isSubmitted && !isValid);

  const handleNewPassword = async (
    password: string
  ): Promise<RespondToAuthChallengeCommandOutput> => {
    return await respondWithNewPassword(password);
  };

  const handleMFA = async (
    username: string,
    {
      ChallengeName,
      ChallengeParameters,
      Session,
    }: Pick<
      RespondToAuthChallengeCommandOutput,
      "ChallengeName" | "ChallengeParameters" | "Session"
    >
  ) => {
    if (!Session) return;

    switch (ChallengeName) {
      case ChallengeNameType.MFA_SETUP: {
        sessionStorage.setItem(SessionStorageItem.Session, Session);

        await setUserMFAPreference(MfaOption.SoftwareTokenMfa, username);
        return navigateToRoute(NavigationRoutes.MfaAuthAppSetup, false);
      }
      case ChallengeNameType.SMS_MFA: {
        sessionStorage.setItem(
          SessionStorageItem.CodeDeliveryDestination,
          ChallengeParameters?.CODE_DELIVERY_DESTINATION ?? ""
        );
        return navigateToRoute(NavigationRoutes.MfaSmsVerification, false);
      }
    }
  };

  const handleSignIn = async (
    username: string,
    password: string
  ): Promise<void> => {
    const response = await signIn(username, password);
    if (!response) {
      setError("root", {
        type: "manual",
        message: "Change password failed.",
      });
      setIsSubmitting(false);
      return;
    }

    if (response.type === "AuthenticationResult") {
      const { result } = response;

      if (result.AccessToken) {
        const tokens = {
          accessToken: result.AccessToken,
          idToken: result.IdToken ?? "",
          refreshToken: result.RefreshToken ?? "",
          expiresIn: result.ExpiresIn ?? 0,
        };

        login(tokens);
        return navigateToRoute(NavigationRoutes.Home);
      } else {
        console.error("AccessToken is undefined.");
      }
    }
  };

  const handleConfirmChangePassword = async ({ newPassword }: FieldValues) => {
    setIsSubmitting(true);

    const username = sessionStorage.getItem(SessionStorageItem.Username) ?? "";
    if (!username) {
      setError("root", {
        type: "manual",
        message: "Username is missing",
      });
      setIsSubmitting(false);
      return;
    }

    // Send the change password command
    const { ChallengeName, ChallengeParameters, Session } =
      await handleNewPassword(newPassword);

    // If no challenge (MFA is disabled) -> sign the user in directly
    if (!ChallengeName || !Session) {
      await handleSignIn(username, newPassword);
    } else {
      // Else (if MFA is enabled) -> handle sign-in via MFA
      await handleMFA(username, {
        ChallengeName,
        ChallengeParameters,
        Session,
      });
    }

    setIsSubmitting(false);
  };

  const handleResetRootError = () => {
    clearErrors("root");
  };

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>Enter new password</Typography>
          <form onSubmit={handleSubmit(handleConfirmChangePassword)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "234px",
              }}
            >
              {errors.root && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--error)",
                    textAlign: "center",
                  }}
                >
                  {errors.root.message}
                </Typography>
              )}
              <TextFieldElement
                name="newPassword"
                type="password"
                control={control}
                onChange={handleResetRootError}
                required
                fullWidth
                inputProps={{
                  "data-testid": "new-user-password--new-password",
                  placeholder: "New password",
                  autoFocus: true,
                }}
                sx={{
                  marginBottom: errors.newPassword ? "3rem" : "0",
                }}
              />
              <TextFieldElement
                name="confirmNewPassword"
                type="password"
                control={control}
                onChange={handleResetRootError}
                required
                fullWidth
                inputProps={{
                  "data-testid": "new-user-password--confirm-new-password",
                  placeholder: "Confirm new password",
                }}
              />
              <AuthButton
                text="Change password"
                type="submit"
                disabled={isSubmitDisabled}
                iconPosition={isSubmitting ? "right" : "none"}
              />
            </Box>
          </form>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};
export default NewUserPassword;
