import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import QRCode from "react-qr-code";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useAuthContext } from "../../../context/AuthContext";
import { SessionStorageItem } from "../../../enums/sessionStorage";
import { verifySecretCodeForAuthApp } from "../../../services/aws/auth";
import { navigateToRoute } from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";
import { useMfaForm } from "./useMfaForm";

const MfaAuthAppSetup = () => {
  const { login } = useAuthContext();
  const formTheme = useAuthFormTheme();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isSubmitted, isValid },
  } = useMfaForm();

  const [isSendingCode, setIsSendingCode] = useState(false);

  const isSubmitDisabled =
    isSendingCode || isEmpty(dirtyFields) || (isSubmitted && !isValid);

  const appName = "Phillips-Connect";
  const username = sessionStorage.getItem(SessionStorageItem.Username) ?? "";
  const secretCodeFromAuthApp =
    sessionStorage.getItem(SessionStorageItem.SecretCodeForAuthApp) ?? "";

  // TOTP App link generation
  const TOTP_APP_LINK = `otpauth://totp/${appName}:${username}?secret=${secretCodeFromAuthApp}&algorithm=SHA1&digits=6&period=60`;

  const setupAppAuthSecretCode = async ({ mfaCode }: FieldValues) => {
    setIsSendingCode(true);
    try {
      const response = await verifySecretCodeForAuthApp(mfaCode);
      if (!response) {
        console.error("Sign-in failed.");
        setIsSendingCode(false);
        return;
      }

      switch (response.type) {
        case "Challenge":
          if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
            // TODO: https://phillips-connect.atlassian.net/browse/PRJIND-9365
            // Check why this step is needed, should be possible to be skipped
            // when we already entered the auth app code once ...
            return navigateToRoute(
              NavigationRoutes.MfaVerifyAuthAppCode,
              false
            );
          }
          break;
        case "AuthenticationResult": {
          const { result } = response;
          if (result.AccessToken) {
            const tokens = {
              accessToken: result.AccessToken,
              idToken: result.IdToken ?? "",
              refreshToken: result.RefreshToken ?? "",
              expiresIn: result.ExpiresIn ?? 0,
            };
            login(tokens);
            return navigateToRoute(NavigationRoutes.Home);
          } else {
            console.error("AccessToken is undefined.");
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error("Sign-in failed.");
      setIsSendingCode(false);
    }

    setIsSendingCode(false);
  };

  const helperTextStyles = {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 100,
  };

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            gap: "3rem",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            Set up application authentication
          </Typography>

          {/* QR Code */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Typography sx={helperTextStyles}>
              Scan this QR code with your authenticator app
            </Typography>
            {TOTP_APP_LINK && (
              <QRCode data-testid="qr-code" value={TOTP_APP_LINK} size={256} />
            )}
          </Box>

          {/* Auth code form */}
          <form onSubmit={handleSubmit(setupAppAuthSecretCode)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "234px",
              }}
            >
              <Typography sx={helperTextStyles}>
                Please enter your 6-digit code from the auth app:
              </Typography>

              <TextFieldElement
                name="mfaCode"
                control={control}
                fullWidth
                autoComplete="off"
                inputProps={{
                  "data-testid": "mfa-auth-app-setup--mfa-code",
                  placeholder: "Code",
                  autoFocus: true,
                }}
              />
              <AuthButton
                text="Send"
                type="submit"
                disabled={isSubmitDisabled}
                iconPosition={isSendingCode ? "right" : "none"}
              />
            </Box>
          </form>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};

export default MfaAuthAppSetup;
