import * as yup from "yup";
import {
  DetentionRuleClockStarts,
  DetentionRuleDayOfDrop,
} from "../../../../../../graphql/operations";
import { transformers } from "../../../../../../utils";
import { MAX_DETENTION_RULES_PER_ORGANIZATION_LIMIT } from "../../configurationsUtils";

export const getDetentionRulesConfigSchema = (
  isEditDrawer: boolean,
  currentRulesLength: number
) => ({
  name: yup
    .string()
    .required("Field is required!")
    .transform(transformers.string),
  company: yup.string().nullable(),
  priority: yup
    .number()
    .min(1)
    .max(
      isEditDrawer
        ? currentRulesLength
        : MAX_DETENTION_RULES_PER_ORGANIZATION_LIMIT,
      "Priority cannot be higher than the current number of rules."
    )
    .required("Please set a valid rule priority!"),
  geofenceType: yup.string().nullable(),
  geofenceSubType: yup.string().nullable(),
  geofenceName: yup.string().nullable(),
  clockStarts: yup
    .string()
    .oneOf(Object.values(DetentionRuleClockStarts))
    .required("Field is required!"),
  dayOfDrop: yup.string().when("clockStarts", {
    is: (clockStarts: DetentionRuleClockStarts) =>
      clockStarts === DetentionRuleClockStarts.DayOfDrop,
    then: (schema) =>
      schema
        .oneOf(Object.values(DetentionRuleDayOfDrop))
        .required("Field is required!"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  endOnEmpty: yup.string().nullable(),
  endOnLoaded: yup.string().nullable(),
});
