import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useAuthContext } from "../../../context/AuthContext";
import { SessionStorageItem } from "../../../enums/sessionStorage";
import { verifySMSCode, resendSMSCode } from "../../../services/aws/auth";
import { TextButton } from "../../../shared/components/Button";
import { navigateToRoute } from "../../../utils/generic";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";
import { useMfaForm } from "./useMfaForm";

const MFASmsValidation: React.FC = () => {
  const { login } = useAuthContext();
  const formTheme = useAuthFormTheme();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitted, isValid },
    setError,
    clearErrors,
  } = useMfaForm();

  const [isLogging, setIsLogging] = useState(false);

  const isSubmitDisabled =
    isLogging || isEmpty(dirtyFields) || (isSubmitted && !isValid);
  const codeDeliveryDestination =
    sessionStorage.getItem(SessionStorageItem.CodeDeliveryDestination) ?? "";

  const verifyCode = async ({ mfaCode }: FieldValues) => {
    setIsLogging(true);

    try {
      clearErrors("root");
      const response = await verifySMSCode(mfaCode);

      const { AccessToken, ExpiresIn, IdToken, RefreshToken } = response;

      if (AccessToken && ExpiresIn && IdToken && RefreshToken) {
        const tokens = {
          accessToken: AccessToken ?? "",
          idToken: IdToken ?? "",
          refreshToken: RefreshToken ?? "",
          expiresIn: ExpiresIn,
        };

        login(tokens);
        sessionStorage.removeItem(SessionStorageItem.Password);
        return navigateToRoute(NavigationRoutes.Home, false);
      } else {
        console.error("Sign in failed.");
      }
    } catch (err) {
      setError("root", {
        type: "manual",
        message: "The code you provided seems to be invalid.",
      });
    }

    setIsLogging(false);
  };

  const handleResendCode = async () => {
    const username = sessionStorage.getItem(SessionStorageItem.Username) ?? "";
    const password = sessionStorage.getItem(SessionStorageItem.Password) ?? "";

    await resendSMSCode(username, password);
  };

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            gap: "3rem",
            flexDirection: "column",
            alignItems: "center",
            width: "234px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 100 }}
          >
            Please enter the 6-digit code sent to your phone number:{" "}
            <b>{codeDeliveryDestination}</b>
          </Typography>
          <form onSubmit={handleSubmit(verifyCode)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "234px",
              }}
            >
              {errors.root && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--error)",
                    textAlign: "center",
                  }}
                >
                  {errors.root.message}
                </Typography>
              )}
              <TextFieldElement
                name="mfaCode"
                control={control}
                fullWidth
                onChange={() => clearErrors("root")}
                inputProps={{
                  "data-testid": "mfa-sms-validation--mfa-code",
                  placeholder: "SMS Code",
                  autoFocus: true,
                }}
              />

              <AuthButton
                text="Sign In"
                type="submit"
                disabled={isSubmitDisabled}
                iconPosition={isLogging ? "right" : "none"}
              />
            </Box>
          </form>

          <Box
            className="mb-6 text-[10px] font-medium"
            data-testid="forgot-password-link"
          >
            Did not get code?
            <TextButton
              text="Request again"
              className="!text-brand"
              onClick={handleResendCode}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};

export default MFASmsValidation;
