import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  SetStateAction,
} from "react";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useGridApiRef, GridRowsProp } from "@mui/x-data-grid-premium";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import bendixLogo from "../../../../../assets/manufacturersLogos/bendix-commercial-vehicles-systems-vector-logo.svg";
import hendricksonLogo from "../../../../../assets/manufacturersLogos/hendrickson-usa-seeklogo.svg";
import hollandLogo from "../../../../../assets/manufacturersLogos/holland_logo_0.svg";
import meritorLogo from "../../../../../assets/manufacturersLogos/meritor-vector-logo.svg";
import safLogo from "../../../../../assets/manufacturersLogos/saf_logo_1.svg";
import wabcoLogo from "../../../../../assets/manufacturersLogos/wabco-vector-logo.svg";
import {
  VOLTAGE_CHART_DATE_RANGE_OPTIONS,
  DateRangeKey,
  AIRTANK_CHART_DATE_RANGE_OPTIONS,
} from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AbsFaultCodeDetails,
  SensorStatusUppercase,
  useGetAirTankSensorHistoricalDataQuery,
  SortOrder,
  useGetAbsFaultCodeHistoricalDataQuery,
  useGetMergedProfileForAssetQuery,
  PsiAirSupplyThresholds,
} from "../../../../../graphql/operations";
import { Table } from "../../../../../shared/components/Table";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import useExportedFileNameForOrg from "../../../../../shared/hooks/useExportedFileNameForOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import {
  formatDate,
  getEndOfToday,
  getStartOfDay,
  getSubDays,
  getSubMonths,
} from "../../../../../utils/date";
import { FIVE_MINUTES_REFETCH_INTERVAL } from "../../../MapView/Assets/SummaryTabPanel/Charts/chartUtils";
import { convertToIsoStringIgnoringTimezoneOffset } from "../../Assets/TiresTabPanel/helpers";
import { AirTankInfoComponent } from "../AirTankInfoComponent/AirTankInfoComponent";
import { AirTankStatus } from "../AirTankStatus/AirTankStatus";
import { AirTankData, BrakesAirTankChart } from "../BrakesAirTankChart";
import AbsFaultCodeDetailsDialog from "./absFaultCodeDialog/absFaultCodeDetailsDialog";
import { getColumns, defaultColumnVisibilityModel } from "./columns";
import { AirTankSummaryData, parseAirTankSummary } from "./helpers";
import { useBrakesTabTheme } from "./useBrakesTabTheme";

export interface BrakesTabProps {
  selectedAssetId: string;
  internalId: string;
  imei?: string | null;
  orgId: string;
}

export const BrakesTab: React.FC<BrakesTabProps> = ({
  selectedAssetId,
  internalId,
  imei,
  orgId,
}) => {
  const logosMap = new Map<string, string>([
    ["holland", hollandLogo],
    ["bendix", bendixLogo],
    ["wabco", wabcoLogo],
    ["hendrickson", hendricksonLogo],
    ["meritor", meritorLogo],
    ["saf", safLogo],
  ]);
  const [startDate, setStartDate] = useState<Date>(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDate, setEndDate] = useState<Date>(getEndOfToday());
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    AIRTANK_CHART_DATE_RANGE_OPTIONS[0].value
  );
  const [sensor, setSensor] = useState<AirTankSummaryData | undefined>(
    undefined
  );
  const [page, setPage] = useState<number>(1);
  const [logo, setLogo] = useState<string>("");
  const [selectedFaultCode, setSelectedFaultCode] =
    useState<AbsFaultCodeDetails>();

  const [showFaultCodeDetailsDialog, setShowFaultCodeDetailsDialog] =
    useState<boolean>(false);
  const gridApiRef = useGridApiRef();

  const userTimezone = usePreferredTimezone();

  const isMobile = useBreakpoint("down", "sm");
  const theme = useBrakesTabTheme();

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: internalId ?? "",
    },
    { enabled: !!internalId }
  );

  const { orgName } = useMemo(
    () => ({
      orgName: selectedAsset?.org_name ?? "",
    }),
    [selectedAsset]
  );

  const {
    data: absFaultCodesData,
    isSuccess: absFaultCodesIsSuccess,
    isLoading: absFaultCodesIsLoading,
  } = useGetAbsFaultCodeHistoricalDataQuery(
    {
      input: {
        skip: 0,
        limit: 10,
        imei: selectedAsset?.imei ?? "",
        customerOrgId: selectedAsset?.customer_orgs_id ?? "",
        period: DateRangeKey.Custom,
        startDate: formatDate(
          getStartOfDay(getSubMonths(new Date(), 6)),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        ),
        endDate: formatDate(new Date(), "yyyy-MM-dd'T'00:00:00.000'Z'"),
        sort: SortOrder.Desc,
      },
    },
    {
      enabled:
        Boolean(selectedAsset?.imei) &&
        Boolean(selectedAsset?.customer_orgs_id),
    }
  );

  const setSelectedLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  const fileName = useExportedFileNameForOrg(orgName, "Brakes");

  const columns = getColumns(userTimezone);
  const searchKeys = columns.map((item) => item.field);

  const tableRows = useMemo(
    () =>
      absFaultCodesData?.searchHistoricalEventHistory?.data
        ?.map((item) => item?.absFaultCodes)
        .filter(Boolean)
        .flat() ?? [],
    [absFaultCodesData]
  );

  useEffect(() => {
    if (selectedAsset?.sensors?.psiAirSupply && !sensor) {
      const sensorSummary = parseAirTankSummary(
        selectedAsset.sensors.psiAirSupply,
        selectedAsset.tripStatus?.tripState
      );
      setSensor(sensorSummary);
    }
  }, [selectedAsset, sensor]);

  const { data: dataAirTank, isLoading: isAirTankLoading } =
    useGetAirTankSensorHistoricalDataQuery(
      {
        input: {
          startDate: convertToIsoStringIgnoringTimezoneOffset(startDate),
          endDate: convertToIsoStringIgnoringTimezoneOffset(endDate),
          imei,
          customerOrgId: orgId,
          sort: SortOrder.Asc,
          includeEmptyDates: true,
          period:
            AIRTANK_CHART_DATE_RANGE_OPTIONS.find(
              (option) => option.value === selectedDateRangeOption
            )?.key ?? DateRangeKey.Last30Days,
        },
      },
      {
        refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
        enabled: Boolean(selectedAssetId) && Boolean(imei) && Boolean(orgId),
      }
    );

  const airTankData = useMemo(() => {
    return dataAirTank?.searchHistoricalEventHistory?.data ?? [];
  }, [dataAirTank]);

  const profileGQL = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: selectedAsset?._id ?? "" },
    },
    { enabled: Boolean(selectedAsset?._id) }
  )?.data;

  const mergedSensorProfile = useMemo(
    () => profileGQL?.getMergedProfileForAsset.sensorProfile ?? null,
    [profileGQL]
  );

  const handleDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOption(value);
    if (option?.getRange) {
      const range = option.getRange();
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  };

  const onPageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleClose = useCallback(() => {
    setShowFaultCodeDetailsDialog(false);
  }, []);

  const onTableRowClick = (faultCode: RowItem) => {
    setSelectedFaultCode(faultCode.row);
    if (faultCode.row.manufacturer) {
      const logoMatch = logosMap.get(
        faultCode.row.manufacturer?.toLocaleLowerCase()
      );
      if (logoMatch) {
        setLogo(logoMatch);
      }
    }
    setShowFaultCodeDetailsDialog(true);
  };

  return isAirTankLoading || absFaultCodesIsLoading ? (
    <Box
      className="flex h-full w-full items-center justify-center"
      data-testid="brakes-loading-screen"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      className="flex h-full flex-col gap-4 px-4 py-4"
      data-testid="brakes-tab-panel"
    >
      {tableRows?.length > 0 && (
        <Grid
          item
          sm={12}
          sx={{
            backgroundColor: "var(--card__bg)",
            borderColor: "var(--asset-card-border)",
            borderRadius: " 0.5rem",
            padding: isMobile ? "8px" : "16px",
          }}
        >
          <Typography
            data-testid="abs-faults-table-label"
            sx={{
              fontSize: "18px",
              color: "var(--brand)",
              fontWeight: 600,
              margin: isMobile ? "8px 0 16px 0" : "0 0 16px 0",
            }}
          >
            Latest ABS Fault Codes
          </Typography>
          <Table
            columns={columns}
            rows={tableRows as GridRowsProp}
            error={
              !absFaultCodesIsLoading && !absFaultCodesIsSuccess ? true : null
            }
            showToolbar={false}
            apiRef={gridApiRef}
            columnVisibilityModel={defaultColumnVisibilityModel}
            tableName={"brakes"}
            getRowId={() => {
              return Math.random();
            }}
            tableHeight="calc(100% - 3.5rem)"
            sx={{
              "& .MuiDataGrid-row": { cursor: "pointer" },
            }}
            allowExport={false}
            onRowClick={onTableRowClick}
            pagination={false}
            enableSearch={false}
            disableRowGrouping
          />
        </Grid>
      )}

      {showFaultCodeDetailsDialog && (
        <AbsFaultCodeDetailsDialog
          faultCode={selectedFaultCode}
          logo={logo}
          onClose={handleClose}
        />
      )}
      {sensor?.status && sensor?.status !== SensorStatusUppercase.Unknown ? (
        <>
          <Grid item container columnSpacing={2} xs={12}>
            {sensor && (
              <Grid item xl={3} lg={3} xs={12}>
                <Grid item xl={12} lg={12} xs={12} paddingBottom={2}>
                  <AirTankStatus sensor={sensor} />
                </Grid>
                <Grid item xl={12} lg={12} xs={12} paddingBottom={2}>
                  <AirTankInfoComponent sensor={sensor} />
                </Grid>
              </Grid>
            )}
            {!sensor && (
              <Grid item xl={3} lg={3} xs={12}>
                <Box className="flex justify-center items-center w-full">
                  <Box
                    className="py-4 px-8 md:p-8 bg-background"
                    data-testid="assets-error-msg"
                  >
                    <Typography className="mb-6 text-lg font-semibold text-typography">
                      There is no such data recorded for this asset.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xl={9} lg={9} xs={12}>
              {!isMobile && (
                <ThemeProvider theme={theme}>
                  <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
                    <Typography className="!text-2xl !font-semibold text-brand">
                      Air Tank Trending Graph
                    </Typography>
                    <Typography
                      className="!text-xs !font-medium text-asset-info-subheader"
                      data-testid="trending-voltage"
                    >
                      Trending pressure data over time
                    </Typography>
                    <Box className="flex items-center justify-end gap-4 py-4">
                      <MobileDatePicker
                        label="Start date"
                        inputFormat="MM/dd/yyyy"
                        closeOnSelect
                        value={startDate}
                        shouldDisableDate={(day) =>
                          day < getStartOfDay(getSubDays(new Date(), 90))
                        }
                        disableFuture
                        onChange={(date) => {
                          setStartDate(date as SetStateAction<Date>);
                          setSelectedLastDateRangeOption();
                        }}
                        DialogProps={{
                          className: "trending-voltage-date-picker",
                        }}
                        renderInput={(params) => (
                          <TextField
                            data-testid="brakes-chart-start-date"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                      <span className="text-base font-normal text-primary">
                        to
                      </span>
                      <MobileDatePicker
                        DialogProps={{
                          className: "trending-voltage-date-picker",
                        }}
                        label="End date"
                        closeOnSelect
                        inputFormat="MM/dd/yyyy"
                        value={endDate}
                        shouldDisableDate={(day) =>
                          !!startDate && day < startDate
                        }
                        disableFuture
                        onChange={(date) => {
                          setEndDate(date as SetStateAction<Date>);
                          setSelectedLastDateRangeOption();
                        }}
                        renderInput={(params) => (
                          <TextField
                            data-testid="brakes-chart-end-date"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />

                      <TextField
                        data-testid="brakes-chart-date-range-select"
                        select
                        value={selectedDateRangeOption}
                        variant="outlined"
                        className="w-40"
                        onChange={(e) => handleDateRangeChange(+e.target.value)}
                      >
                        {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            data-testid={`brakes-chart-date-range-select-option-${option.value}`}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <BrakesAirTankChart
                      data={airTankData as AirTankData[]}
                      sensorProfile={
                        mergedSensorProfile?.configuration?.psiAirSupply ?? null
                      }
                    />
                  </Box>
                </ThemeProvider>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          className="text-sm bg-dashboard_subheader__bg p-6 rounded-lg text-asset-info-subheader"
          data-testid="other-sensors-tab-panel-no-data"
        >
          No Sensors Available.
        </Box>
      )}
    </Box>
  );
};

type RowItem = {
  row: AbsFaultCodeDetails;
};
