import { ReactElement, useMemo } from "react";
import { useCookies } from "react-cookie";
import { USER_REDIRECT_URL } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { InitialDashboardSequence } from "../../enums/dashboards";
import { useGetUserDataQuery } from "../../graphql/operations";
import { NavigationRoutes } from "./routesUtils";

export enum RouteComponents {
  AssetsView = "AssetsView",
  DeviceManagement = "DeviceManagement",
  BatchManagement = "BatchManagement",
  DashboardsView = "DashboardsView",
  ReportsListView = "ReportsListView",
  AlertsView = "AlertsView",
  AdminPanel = "AdminPanel",
  MyAccount = "MyAccount",
  Home = "Home",

  Geofences = "Geofences",

  Brands = "Brands",
  Configurations = "Configurations",
  Organizations = "Organizations",
  Profiles = "Profiles",
  Sensors = "Sensors",
  Users = "Users",
  Regions = "Regions",
  Zones = "Zones",
  Automations = "Automations",
  DeviceDashboard = "DeviceDashboard",
  DeviceManagementView = "DeviceManagementView",
}

interface MappedSecurityRouteComponents {
  [key: string]: boolean;
}
export function useCheckRouteSecurity() {
  const [cookies, setCookie, removeCookie] = useCookies([USER_REDIRECT_URL]);
  const { userRolePermissions, isAuthorized } = useAuthContext();
  const { data, isLoading: isLoadingDefaultPath } = useGetUserDataQuery(
    undefined,
    {
      enabled: isAuthorized,
    }
  );

  const defaultPath = useMemo(() => {
    const redirectURL = cookies[USER_REDIRECT_URL];
    if (redirectURL) {
      return redirectURL;
    }
    // If the user can view dashboards at all
    if (userRolePermissions.dashboard.view) {
      const currentOrg = data?.me?.customerOrg;
      const defaultDashboardId =
        currentOrg?.default_dashboard ??
        InitialDashboardSequence.OrganizationDashboard;

      return `${NavigationRoutes.Dashboard}/${defaultDashboardId}?orgId=${currentOrg?._id}`;
    }
    return NavigationRoutes.MyAccount;
  }, [cookies, data?.me?.customerOrg, userRolePermissions.dashboard.view]);

  const mappedSecurityRouteComponents: MappedSecurityRouteComponents = {
    // root
    [RouteComponents.DashboardsView]: userRolePermissions.dashboard.view,
    [RouteComponents.AssetsView]: userRolePermissions.asset.view,
    [RouteComponents.MyAccount]: true,
    [RouteComponents.DeviceManagement]: userRolePermissions.device.view,
    [RouteComponents.BatchManagement]: userRolePermissions.batches.view,
    [RouteComponents.ReportsListView]: userRolePermissions.reports.view,
    [RouteComponents.AlertsView]: userRolePermissions.alerts.view,
    [RouteComponents.AdminPanel]: userRolePermissions.adminPanel.view,

    [RouteComponents.Geofences]: userRolePermissions.geofences.view,

    [RouteComponents.Brands]: userRolePermissions.brands.view,
    [RouteComponents.Organizations]: userRolePermissions.org.view,
    [RouteComponents.Users]: userRolePermissions.user.view,
    [RouteComponents.Profiles]: userRolePermissions.profiles.view,
    [RouteComponents.Configurations]: userRolePermissions.configurations.view,
    [RouteComponents.Sensors]: userRolePermissions.sensors.view,
    [RouteComponents.Home]: userRolePermissions.home.view,
    [RouteComponents.Regions]: userRolePermissions.regions.view,
    //Keep this optional chaining as tests fail without it for some reason
    [RouteComponents.Zones]: userRolePermissions.zones?.view,
    [RouteComponents.Automations]: userRolePermissions.automations?.view,
    [RouteComponents.DeviceDashboard]: userRolePermissions.device.view,
    [RouteComponents.DeviceManagementView]: userRolePermissions.device.view,
  };

  const hasRoleAccess = (component: ReactElement) => {
    // handle all possible ways to get the name of the component
    // 1. Lazy loaded component
    // 2. Memoized component
    // 3. Regular functional component

    const componentName =
      (component?.type as Record<string, any>)?.displayName ??
      (component?.type as Record<string, any>)?.type?.displayName ??
      (component?.type as Record<string, any>)?.name;

    const hasAccess = Boolean(mappedSecurityRouteComponents[componentName]);
    return hasAccess;
  };

  return { hasRoleAccess, defaultPath, isLoadingDefaultPath };
}
